
/*首先对字体进行引用*/
@font-face {font-family: 'SourceHanSansCN-Bold';src: url(../font/SourceHanSansCN-Bold.otf);font-weight: normal;font-style: normal;}
@font-face {font-family: 'SourceHanSansCN-ExtraLight';src: url(../font/SourceHanSansCN-ExtraLight.otf);font-weight: normal;font-style: normal;}
@font-face {font-family: 'SourceHanSansCN-Heavy';src: url(../font/SourceHanSansCN-Heavy.otf);font-weight: normal;font-style: normal;}
@font-face {font-family: 'SourceHanSansCN-Light';src: url(../font/SourceHanSansCN-Light.otf);font-weight: normal;font-style: normal;}
@font-face {font-family: 'SourceHanSansCN-Medium';src: url(../font/SourceHanSansCN-Medium.otf);font-weight: normal;font-style: normal;}
@font-face {font-family: 'SourceHanSansCN-Regular';src: url(../font/SourceHanSansCN-Regular.otf);font-weight: normal;font-style: normal;}
@font-face {font-family: 'SourceHanSansCN-Normal';src: url(../font/SourceHanSansCN-Normal.otf);font-weight: normal;font-style: normal;}

@font-face {font-family: 'Roboto-Light';src: url(../font/Roboto-Light.ttf);font-weight: normal;font-style: normal;}

/* height:100vh; */
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height:0; */
}
body {
  cursor: default;
  /* color: #3c4858; */
  margin: 0;
  font-family: SourceHanSansCN-Normal,Roboto-Light;
  font-weight: 300;
  /* line-height: 1.5em; */
  height:0;
  /* overflow: hidden; */
  /* height:100%; */
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  /* 禁止选中文字 */
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* 禁止选中文字 会导致导致 input 框无法输入，光标不出现 */
input[type] {
  -moz-user-select: text;
  -o-user-select: text;
  -khtml-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-select: text;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* material icons */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* * 图标字体 */
@font-face {
  font-family: 'font_family';
  src: url('../font/iconfont.eot?');
  src: url('../font/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../font/iconfont.woff2') format('woff2'),
  url('../font/iconfont.woff') format('woff'),
  url('../font/iconfont.ttf') format('truetype'),
  url('../font/iconfont.svg#font_family') format('svg');
}

.font_family{
  font-family:"font_family" !important;
  font-size: 0.24rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 


/* 2019年12月3日16:02:36 重写会议信息日历插件样式 */
.react-calendar{
  border: none;
  background: #111;
  font-family: "SourceHanSansCN-Normal,Roboto-Light";
}
.react-calendar__month-view__weekdays__weekday{
  color: #747477;
  padding: .05rem;
  background: #111;
}
.react-calendar__tile{
  color: #aaaaa9;
  background: #111;
  padding: .05rem;
  white-space: nowrap;
  text-align: center;
  font-size: .16rem;
  border: 0;
  cursor: pointer;
}
.react-calendar__tile *{
  font-size: .16rem;
  display: inline-block;
  border-bottom: solid .01rem #111;
  color: #aaaaa9;
}
.react-calendar__month-view__days__day--neighboringMonth *{
  color: #747477
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.10rem 0.05rem;
}
/* .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #E2CC4B;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background: #111;
  color: #aaa;
} */
.react-calendar button:enabled:hover{
  background: #E2CC4B;
}
.react-calendar button:enabled:hover *{
  color: #111;
  border-color: #E2CC4B;
}
.active .react-calendar__tile--active,.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
  background: #E2CC4B;
  color: #111;
}
.active .react-calendar__tile--active *{
  color: #111;
  border-bottom: #E2CC4B;
}
.react-calendar__tile--active:enabled:focus *{
  color: #111;
  border-color: #E2CC4B;
}
.react-calendar__tile--now *{
  color: #E2CC4B;
  border-color: #E2CC4B;

}
.react-calendar__navigation__arrow *{
    color: white;
}
.react-calendar__navigation__label {
    color: white;
    font-size: 0.17rem;
}
.react-calendar__navigation button:enabled:focus {
    background: none;
}

.react-calendar__navigation {
    height: 0.44rem;
    margin-bottom: 0;
}

.react-calendar__navigation button {
    min-width: 0.44rem;
}

.react-calendar button {
    color: white;
    font-size: 0.20rem;
}
.react-calendar__month-view__weekdays__weekday {
    font-size: 0.15rem;
}
.react-calendar__tile:disabled{
  background: #111;
  cursor: default;
}
.react-calendar__tile:disabled *{
  border-bottom:0;
  color: #3d3d3d
}
.react-calendar__navigation button[disabled] {
  background-color: #747474;
}
.react-date-picker--disabled{
  opacity: .5;
}
/* .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: #111111;
} */

/* */
.ldwrap{
	position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -1.2rem;
    margin-top: -0.15rem;
}
p {
  color: #eaeaea;
	width: 2.4rem;
	height: 0.3rem;
	margin-top: 0.5rem;
	font-size: 0.3rem;
	text-align: center;
	letter-spacing: 0.04rem;
	line-height: 0.3rem;
}
.k-line2 {
	display:inline-block;
	background:#666;
	height:20px;
	width:20px;
	opacity:0;
	border-radius:50%;
	transform:translateX(-400px);
	background-color:#4b9cdb
}
.k-line12-1 {
	background:#ae57e3;
	animation:k-loadingS 2s infinite;
	animation-delay:.32s
}
.k-line12-2 {
	background:#00a6ff;
	animation:k-loadingS 2s infinite;
	animation-delay:.24s
}
.k-line12-3 {
	background:#59ad6d;
	animation:k-loadingS 2s infinite;
	animation-delay:.16s
}
.k-line12-4 {
	background:#e2cc4b;
	animation:k-loadingS 2s infinite;
	animation-delay:.08s
}
.k-line12-5 {
	background:#d06028;
	animation:k-loadingS 2s infinite;
	animation-delay:.02s
}@keyframes k-loadingS {
	40% {
	transform:translateX(0);
	opacity:.8
}
100% {
	transform:translateX(400px);
	opacity:0
}
}


textarea::-webkit-scrollbar{
    width: 7px; /* 纵向滚动条*/
    right: 4px;
    height: 7px; /* 横向滚动条 */
}

textarea::-webkit-scrollbar-thumb{
    width: 3px; /* 纵向滚动条*/
    height: 3px; /* 横向滚动条 */
    right: 4px;
    border-radius: 4px;
    background-color: #747474;
}
textarea::-webkit-scrollbar:window-inactive {
    width: 0;
    height: 0;
}
/* 自动填充样式 */
input:-webkit-autofill {
  box-shadow: none!important;
  -webkit-text-fill-color: #EAEAEA;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  background-color: transparent;
  font-size: 40px!important;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}

/*定义滚动条轨道 内阴影*/
/* ::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
  background-color: #fff;
}
:hover::-webkit-scrollbar {display:block; }
::-webkit-scrollbar-thumb {border-radius: 2rem;background: #747474;} */


/* 
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}


small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}


blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  .badge-primary-background-color: #9c27b0;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
} */
